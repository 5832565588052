<template>
  <div>
    <div class="d-flex align-items-center px-0 mb-3">
      <div class="text-muted font-weight-medium h6 col px-0">Orçamentos</div>
      <b-button
        pill
        variant="primary"
        size="sm"
        class="col-auto"
        @click="openModal()"
        v-if="hasModalFields"
      >
        <b-icon icon="plus"></b-icon>
        Adicionar
      </b-button>
    </div>
    <template v-if="hasModalFields">
      <how-much-item-all
        :fields="modelValue"
        @open-item="openModal(field, key)"
        @remove-item="removeItem(key)"
      />
      <how-much-item
        v-for="(field, key) of modelValue"
        :field="field"
        :key="'how_much_' + key"
        @open-item="openModal(field, key)"
        @remove-item="removeItem(key)"
      />
    </template>
    <b-list-group-item v-else class="border-0 text-center">
      <b-icon icon="currency-dollar" font-scale="1.5" class="primary" />
      <h6>Sem orçamentos adicionados</h6>
      <p class="text-muted">
        Os orçamentos adicionados serão exibidos nessa listagem
      </p>
      <b-button pill variant="primary" size="sm" @click="openModal()">
        <b-icon icon="plus"></b-icon>
        Adicionar
      </b-button>
    </b-list-group-item>
    <b-modal
      :title="titleModal"
      centered
      v-model="state.openedModal"
      scrollable
      size="lg"
      ok-title="Salvar"
      cancel-title="Cancelar"
      no-close-on-esc
      @ok="addHowMuch"
      @hide="clearCurrentValues"
    >
      <form-how-much-item v-model="state.currentHowMuch" />
    </b-modal>
  </div>
</template>
<script setup>
import { reactive, computed } from "vue";
import _ from "lodash";

import FormHowMuchItem from "@/modules/action_plans/components/Form/FormHowMuchItem.vue";
import HowMuchItemAll from "@/modules/action_plans/components/Form/HowMuchItemAll.vue";
import HowMuchItem from "@/modules/action_plans/components/Form/HowMuchItem.vue";
import { useVModel } from "@/composables/useVModel";

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits(["input"]);
const modelValue = useVModel(props, "value", emits);

const state = reactive({
  currentHowMuch: {
    budget: 0,
    realized: 0,
    source_type: "none",
    description: "",
  },
  currentIndexHowMuch: -1,
  openedModal: false,
});

const isEditMode = computed(() => !!state.currentHowMuch?.id);
const hasModalFields = computed(
  () => _.isArray(modelValue.value) && !_.isEmpty(modelValue.value)
);
const titleModal = computed(() =>
  isEditMode.value ? "Editar orçamento" : "Novo orçamento"
);

function addHowMuch() {
  if (state.currentIndexHowMuch === -1) {
    modelValue.value.push(state.currentHowMuch);
  } else {
    modelValue.value.splice(state.currentIndexHowMuch, 1, state.currentHowMuch);
  }
  state.currentIndexHowMuch = -1;
  clearCurrentValues();
}

function clearCurrentValues() {
  state.currentHowMuch = {
    budget: 0,
    realized: 0,
    description: "",
    source_type: "none",
  };
}

function removeItem(key = -1) {
  if (key === -1) {
    return;
  }
  modelValue.value.splice(key, 1);
}

function openModal(item, key = -1) {
  if (item) {
    state.currentHowMuch = Object.assign({}, item);
    state.currentIndexHowMuch = key;
  }
  state.openedModal = true;
}
</script>
