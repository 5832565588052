<template>
  <b-form class="column">
    <b-form-group label="Orçamento">
      <number-format v-model="modelValue.budget" />
    </b-form-group>
    <b-form-group label="Realizado">
      <number-format v-model="modelValue.realized" />
    </b-form-group>
    <b-form-group label="Descrição">
      <b-form-textarea
        v-model="modelValue.description"
        class="max-height-description"
      />
    </b-form-group>
    <b-form-group label="Origem">
      <input-chip v-model="modelValue.source_type" :options="resourceType" />
    </b-form-group>
  </b-form>
</template>
<script setup>
import InputChip from "@/components/Form/InputChip.vue";
import NumberFormat from "@/components/Form/NumberFormat.vue";
import { useVModel } from "@/composables/useVModel";

const props = defineProps({
  value: {
    type: Object,
    default: () => ({
      budget: 0,
      realized: 0,
      source_type: "none",
    }),
  },
});
const emits = defineEmits(["input"]);
const modelValue = useVModel(props, "value", emits);
const resourceType = [
  { value: "national", text: "Nacional" },
  { value: "state", text: "Estadual" },
  { value: "municipal", text: "Municipal" },
  { value: "private", text: "Privado" },
  { value: "none", text: "Não especificado" },
];
</script>
<style scoped lang="scss">
.max-height-description {
  min-height: 80px;
  max-height: 200px;
}
</style>
