<template>
  <b-card class="border-0" no-body>
    <b-form class="row">
      <div class="col-12 text-muted font-weight-medium h6">5W</div>
      <b-form-group label="O que será feito?" class="col-12">
        <b-form-input v-model="formModel.what" />
      </b-form-group>
      <b-form-group label="Por quê?" class="col-12">
        <b-form-textarea
          v-model="formModel.why"
          class="max-height-description"
        />
      </b-form-group>
      <b-form-group label="Onde?" :class="gridClass">
        <b-form-input v-model="formModel.where" />
      </b-form-group>
      <b-form-group label="Quem será o responsável?" :class="gridClass">
        <form-responsaveis
          v-model="formModel.who_id"
          @model_type="(val) => (formModel.who_type = val)"
          :model_type="formModel.who_type"
        />
      </b-form-group>
      <b-form-group label="Quando será iniciado?" class="col-6">
        <date-input v-model="formModel.when_start" />
      </b-form-group>
      <b-form-group label="Prazo de Entrega" class="col-6">
        <date-input v-model="formModel.when" />
      </b-form-group>
      <div class="col-12 text-muted font-weight-medium h6">2H</div>
      <b-form-group label="Como?" class="col-12">
        <b-form-textarea
          v-model="formModel.how"
          class="max-height-description"
        />
      </b-form-group>
      <b-form-group label="Quanto?" class="col-12">
        <b-form-textarea
          v-model="formModel.how_much"
          class="max-height-description"
        />
      </b-form-group>
      <form-how-much v-model="formModel.action_plans_how_much" class="col-12" />
    </b-form>
  </b-card>
</template>
<script setup>
import { computed } from "vue";
import FormResponsaveis from "@/modules/action_plans/components/Form/FormResponsaveis.vue";

import DateInput from "@/components/Form/DateInput.vue";
import FormHowMuch from "@/modules/action_plans/components/Form/FormHowMuch.vue";
import { useVModel } from "@/composables/useVModel";

const props = defineProps({
  value: {
    type: Object,
    default: () => ({
      why: "",
      where: "",
      who: "",
      who_type: "",
      when: "",
      how: "",
      how_much: "",
      what: "",
      action_plans_how_much: [],
    }),
  },
  gridMode: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["input"]);

const formModel = useVModel(props, "value", emits);
const gridClass = computed(() =>
  props.gridMode ? "col-12 col-md-6" : "col-12"
);
</script>
<style scoped lang="scss">
.max-height-description {
  min-height: 80px;
  max-height: 200px;
}
</style>
