<template>
  <b-list-group>
    <div class="text-muted font-weight-medium h6">Detalhes da ação</div>
    <b-list-group-item
      v-for="(field, key) of mappedFields"
      :key="'field_item_' + key"
      class="flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">{{ field.title }}</h6>
      </div>
      <p class="mb-1 text-muted" v-if="formatedListDetails[key]">
        {{ formatedListDetails[key] }}
      </p>
      <p class="mb-1 text-muted" v-else>
        {{ field.helperText }}
      </p>
    </b-list-group-item>
  </b-list-group>
</template>
<script setup>
import { computed } from "vue";

import format_date from "@/helpers/filters/format_date";

const mappedFields = {
  what: { title: "O que será feito?", helperText: "Não definido" },
  why: { title: "Por quê?", helperText: "Não definido" },
  where: { title: "Onde?", helperText: "Não definido" },
  who: { title: "Responsável", helperText: "Não definido" },
  when_start: { title: "Quando?", helperText: "Não definido" },
  how: { title: "Como?", helperText: "Não definido" },
  how_much: { title: "Quanto custará?", helperText: "Não definido" },
};
const props = defineProps({
  listDetails: {
    type: Object,
    default: () => ({}),
  },
});

const formatedListDetails = computed(() => {
  const target = {};
  for (const key of Object.keys(props.listDetails)) {
    target[key] = props.listDetails[key];

    if (key === "when_start") {
      target[key] = format_date(props.listDetails[key]);
    }
  }
  return target;
});
</script>
