<template>
  <b-dropdown
    v-bind="$attrs"
    :text="getTitle()"
    no-flip
    right
    toggle-class="rounded-pill pl-2"
    variant="outline-primary"
  >
    <b-dropdown-item-button
      v-for="(status, key) of mappedStatus"
      :key="'status_' + key"
      :active="getActiveValue(key)"
      @click="selectValue(key)"
    >
      {{ status.title }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>
<script setup>
import { useMapGetters } from "@/composables/useMapGetters";
import { useVModel } from "@/composables/useVModel";

const props = defineProps({
  value: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["input"]);

const modelValue = useVModel(props, "value", emits);
const { GetMappedStatus } = useMapGetters("action_plans", ["GetMappedStatus"])
const mappedStatus = GetMappedStatus()

function selectValue(value) {
  modelValue.value = value;
}

function getActiveValue(value) {
  return String(modelValue.value).includes(value);
}

function getTitle() {
  const key = modelValue.value;
  return mappedStatus[key]?.title ?? "Status";
}
</script>
