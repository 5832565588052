<template>
  <b-list-group-item class="flex-column rounded border mb-3">
    <div class="row no-gutters align-items-center">
      <div class="col-auto text-primary">
        <span class="d-block text-secondary text-caption2">Orçamento</span>
        {{ formatValue(total) }}
      </div>
      <div class="col"></div>
      <div class="col-auto text-primary text-right">
        <span class="d-block font-weight-normal text-muted text-caption2">
          Realizado
        </span>
        {{ formatValue(realized) }}
      </div>
    </div>
    <div class="mt-2 mb-3">
      <b-progress height="4px" :value="progressValue" />
    </div>
    <div class="row no-gutters">
      <div class="text-secondary text-center col">
        <span class="d-block text-muted text-caption2"> Nacional </span>
        {{ formatValue(source_nacional) }}
      </div>
      <div class="text-secondary text-center col">
        <span class="d-block text-muted text-caption2"> Estadual </span>
        {{ formatValue(source_estadual) }}
      </div>
      <div class="text-secondary text-center col">
        <span class="d-block text-muted text-caption2">
          Municipal
        </span>
        {{ formatValue(source_municipal) }}
      </div>
      <div class="text-secondary text-center col">
        <span class="d-block text-muted text-caption2"> Privado </span>
        {{ formatValue(source_privada) }}
      </div>
      <div class="text-secondary text-center col">
        <span class="d-block text-muted text-caption2"> -- </span>
        {{ formatValue(source_none) }}
      </div>
    </div>
    <hr class="my-3" />
  </b-list-group-item>
</template>
<script setup>
import { computed } from "vue";

import number_format from "@/helpers/filters/number_format";

const props = defineProps({
  fields: {
    type: Array,
  },
});

defineEmits(["open-item"]);

const total = computed(() => {
  let total = 0;
  props.fields.forEach((item) => {
    total += item.budget;
  });
  return total;
});

const realized = computed(() => {
  let total = 0;
  props.fields.forEach((item) => {
    total += item.realized;
  });
  return total;
});

const source_nacional = computed(() => {
  let total = 0;
  props.fields.forEach((item) => {
    total += item.source_type == "national" ? item.budget : 0;
  });
  return total;
});

const source_estadual = computed(() => {
  let total = 0;
  props.fields.forEach((item) => {
    total += item.source_type == "state" ? item.budget : 0;
  });
  return total;
});

const source_municipal = computed(() => {
  let total = 0;
  props.fields.forEach((item) => {
    total += item.source_type == "municipal" ? item.budget : 0;
  });
  return total;
});

const source_privada = computed(() => {
  let total = 0;
  props.fields.forEach((item) => {
    total += item.source_type == "private" ? item.budget : 0;
  });
  return total;
});

const source_none = computed(() => {
  let total = 0;
  props.fields.forEach((item) => {
    total += item.source_type == "none" ? item.budget : 0;
  });
  return total;
});

const progressValue = computed(() => {
  if (total.value === 0) {
    return 0;
  }

  return (realized.value / total.value) * 100;
});

function formatValue(value = 0) {
  return "R$ " + number_format(value);
}
</script>
<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
