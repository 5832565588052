<template>
  <b-list-group-item class="flex-column rounded border mb-3">
    <div class="row no-gutters align-items-center">
      <div class="col text-primary">
        <span class="d-block text-secondary text-caption2">Recurso</span>
        {{ source_type }}
      </div>

      <div class="col text-primary">
        <span class="d-block text-secondary text-caption2">Orçamento</span>
        {{ formatValue(field.budget) }}
      </div>

      <div class="col-auto text-primary text-right">
        <span class="d-block font-weight-normal text-muted text-caption2">
          Realizado
        </span>
        {{ formatValue(field.realized) }}
      </div>
    </div>
    <div class="mt-2 mb-3">
      <b-progress height="4px" :value="progressValue" />
    </div>

    <hr class="my-3" />
    <div class="d-flex">
      <b-btn
        pill
        variant="outline-danger"
        size="sm"
        class="ml-auto mr-2"
        @click="$emit('remove-item')"
      >
        <b-icon icon="trash" />
        Remover
      </b-btn>
      <b-btn
        pill
        variant="outline-primary"
        size="sm"
        @click="$emit('open-item', field)"
      >
        <b-icon icon="pencil-square" />
        Editar
      </b-btn>
    </div>
  </b-list-group-item>
</template>
<script setup>
import { computed } from "vue";

import number_format from "@/helpers/filters/number_format";

const props = defineProps({
  field: {
    type: Object,
    default: () => ({
      budget: 0,
      realized: 0,
      source_nacional: 0,
      source_estadual: 0,
      source_municipal: 0,
      source_privada: 0,
    }),
  },
});

defineEmits(["open-item"]);

const source_types = {
  national: "Nacional",
  state: "Estadual",
  municipal: "Municipal",
  private: "Privado",
  none: "Não Definido",
};

const source_type = computed(() => {
  return source_types[props.field.source_type];
});

const progressValue = computed(() => {
  const budget = props.field.budget || 0;
  const realized = props.field.realized || 0;

  if (budget === 0) {
    return 0;
  }

  return (realized / budget) * 100;
});

function formatValue(value = 0) {
  return "R$ " + number_format(value);
}
</script>
<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
