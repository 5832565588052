<template>
  <div class="row no-gutters">
    <div
      v-for="(option, key) in options"
      :key="'selectable_option_' + key"
      class="col-auto mr-2"
    >
      <b-btn
        :variant="getActiveColor(option.value)"
        @click="modelValue = option.value"
        :disabled="option.disabled"
        size="sm"
        pill
      >
        <slot name="label" :option="option">
          <span class="mr-1" v-if="!!option.icon">
            <font-awesome-icon :icon="option.icon" />
          </span>
          {{ option.text }}
        </slot>
      </b-btn>
    </div>
    <small v-if="!!hint" class="text-secondary mt-2 w-100">{{ hint }}</small>
  </div>
</template>
<script setup>
import { useVModel } from "@/composables/useVModel";

const props = defineProps({
  value: {
    type: [String, Number],
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
  hint: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["change"]);

const modelValue = useVModel(props, "value", emits);

function getActiveColor(value) {
  return modelValue.value === value ? "primary" : "outline-secondary";
}
</script>
