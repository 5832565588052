<template>
  <div class="container px-0 mb-5">
    <the-header />
    <b-card class="border-0 radius-top-0 mb-5" body-class="pt-0">
      <b-tabs pills nav-wrapper-class="mb-4 pb-2 border-bottom">
        <b-tab title="Ações">
          <list-actions-base />
        </b-tab>
        <b-tab title="Dados básicos">
          <form-base @submit="onSubmit" v-model="state.form" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script setup>
import { computed, onMounted, reactive } from "vue";

import TheHeader from "@/modules/action_plans/components/TheHeader.vue";
import FormBase from "@/modules/action_plans/components/Form/FormBase.vue";
import ListActionsBase from "@/modules/action_plans/components/List/ListActionsBase.vue";

import { useRoute } from "@/composables/useRoute";
import { useMapActions } from "@/composables/useMapActions";

const route = useRoute();
const state = reactive({
  form: {},
});

const objectiveId = computed(
  () => route.query.objectiveId || state.form.objective?.id
);
const idPlan = computed(() => route.params.id);

const { ActionShow, ActionNew } = useMapActions("objectives", [
  "ActionShow",
  "ActionNew",
]);

const { ActionSave, ActionGetOnePlan } = useMapActions("action_plans", [
  "ActionSave",
  "ActionGetOnePlan",
]);

async function loadObjectiveData() {
  try {
    await ActionNew();
    await ActionShow({ id: objectiveId.value });
  } catch (error) {}
}

async function loadData() {
  try {
    const result = await ActionGetOnePlan({ id: idPlan.value });
    state.form = result.action_plan;
  } catch (error) {}
}

async function onSubmit() {
  try {
    if (state.form.objective && "id" in state.form.objective) {
      state.form.objective_id = state.form.objective.id;
    }
    const result = await ActionSave(state.form);
  } catch (error) {}
}

onMounted(() => {
  loadData();
  if (objectiveId.value) {
    loadObjectiveData();
  }
});
</script>
<style scoped lang="scss"></style>
