<template>
  <div>
    <b-table
      :fields="fields"
      :items="formatedListItems"
      show-empty
      class="border-top-0"
      thead-tr-class="font-weight-normal justify-content-center"
      tbody-tr-class="text-secondary"
    >
      <template #head(actions)>
        <b-button variant="primary" @click="openModal" size="sm" pill>
          <b-icon icon="plus"></b-icon>
          Nova ação
        </b-button>
      </template>
      <template #cell(status)="row">
        <b-badge
          :variant="getColorStatusCell(row.item.status)"
          class="font-weight-normal"
          pill
        >
          {{ getStatusTitle(row.item.status) }}
        </b-badge>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="openItemOnModal(row.item)"
          pill
          variant="outline-primary"
          class="mr-1"
        >
          <b-icon icon="pencil-square" />
        </b-button>
        <b-button
          pill
          size="sm"
          title="Nova sub-ação"
          v-b-tooltip.hover
          variant="outline-primary"
          @click="newChildItem(row.item)"
        >
          <b-icon icon="plus" />
        </b-button>
        <b-button
          v-if="hasChildren(row.index)"
          size="sm"
          @click="row.toggleDetails"
          variant="link-dark"
          class="ml-1"
        >
          <b-icon :icon="getIconToExpand(row.detailsShowing)" />
        </b-button>
      </template>
      <template #row-details="row">
        <b-list-group>
          <list-child-item
            :items="row.item.children"
            @edit-action="(item) => openItemOnModal(item)"
          />
        </b-list-group>
      </template>
      <template #empty>
        <b-card class="border-0 text-center py-5">
          <b-card-text>
            <b-icon icon="exclamation-circle" class="h3" variant="warning" />
            <p class="text-muted">Ainda não foram cadastradas ações</p>
          </b-card-text>
        </b-card>
      </template>
    </b-table>
    <b-modal
      v-model="state.openedModal"
      @hide="clearForm"
      @ok="submitActionItem"
      size="xl"
      centered
      scrollable
      body-class="row"
      footer-class="no-gutters"
      header-class="no-gutters align-items-center"
    >
      <template #modal-header="{ close }">
        <div class="col-auto mr-auto">
          <div class="h6 my-0">{{ titleModalForm }}</div>
        </div>
        <select-status
          class="col-auto"
          v-model="state.form.status"
          size="sm"
          pill
        />
        <div class="col-auto">
          <b-button @click="close" variant="link-dark">
            <b-icon icon="x"></b-icon>
          </b-button>
        </div>
      </template>
      <div :class="formListActionsClass">
        <form-list-actions
          v-model="state.form"
          :grid-mode="!state.showPreview"
        />
      </div>
      <div class="col-12 col-md-6" v-show="state.showPreview">
        <action-base-details :list-details="state.form" />
      </div>
      <template #modal-footer="{ cancel, ok }">
        <div class="col-auto mr-auto">
          <b-form-checkbox
            v-model="state.showPreview"
            switch
            class="text-muted cursor-pointer"
          >
            <span>{{ labelSwitchButton }}</span>
          </b-form-checkbox>
        </div>
        <div class="col-auto">
          <b-button @click="cancel" class="mr-2">Cancelar</b-button>
          <b-button @click="ok" variant="primary">Salvar</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script setup>
import { computed, onMounted, reactive } from "vue";
import { debounce, groupBy, isArray, isEmpty } from "lodash";

import FormListActions from "@/modules/action_plans/components/Form/FormListActions.vue";
import ActionBaseDetails from "@/modules/action_plans/components/ActionBaseDetails.vue";
import SelectStatus from "@/modules/action_plans/components/Form/SelectStatus.vue";
import ListChildItem from "@/modules/action_plans/components/List/ListChildItem.vue";

import { useMapActions } from "@/composables/useMapActions";
import { useRoute } from "@/composables/useRoute";
import { useNotification } from "@/composables/useNotification";
import format_date from "@/helpers/filters/format_date";
import { useMapGetters } from "@/composables/useMapGetters";

const emits = defineEmits(["new-action"]);

const fields = [
  {
    key: "what",
    label: "Título",
    class: "col-6",
    formatter: (value) => value || "Não definido",
  },
  {
    key: "status",
    label: "Status",
    class: "text-center",
    formatter: (value) => `---- ${value}`,
  },
  {
    key: "who",
    label: "Responsável",
    class: "text-center col-2",
  },
  {
    key: "when",
    label: "Quando?",
    class: "text-center",
    formatter: (value) => format_date(value),
  },
  {
    key: "actions",
    label: "",
    class: "col-auto text-right",
  },
];

const $route = useRoute();
const $notify = useNotification();
const state = reactive({
  items: [],
  form: {
    why: "",
    where: "",
    who: "",
    when: "",
    how: "",
    how_much: "",
    what: "",
    action_plans_how_much: [],
  },
  openedModal: false,
  showPreview: true,
});

const actionPlanId = computed(() => $route.params.id);
const labelSwitchButton = computed(() =>
  state.showPreview ? "Ocultar pré-visualização" : "Exibir pré-visualização"
);
const titleModalForm = computed(() => {
  const labelAction = !!state.form.parent_id ? "sub-ação" : "ação";
  const typeAction = !!state.form.id ? "Editar" : "Nova";
  return `${typeAction} ${labelAction}`;
});

const formListActionsClass = computed(() =>
  state.showPreview ? "col-md-6 col-12" : "col-12"
);

const formatedListItems = computed(() => {
  const items = isArray(state.items) ? state.items.slice() : [];
  const itemsGroupedByParentId = groupBy(items, (item) => item.parent_id);

  const recursiverlyChild = (callback) => {
    return (child) => {
      const listChild = callback(child.id);
      const hasChildren = listChild?.length > 0;

      if (hasChildren) {
        return Object.assign(child, { children: listChild });
      }
      return child;
    };
  };

  const childOf = (parentId) => {
    const mainList = itemsGroupedByParentId[parentId] || [];
    return mainList.map(recursiverlyChild(childOf));
  };
  return childOf(null);
});

const { GetMappedStatus } = useMapGetters("action_plans", ["GetMappedStatus"]);
const mappedStatus = GetMappedStatus();
const { ActionGetAllItems, ActionSavePlanItem, ActionSaveHowMuchItems } =
  useMapActions("action_plans", [
    "ActionGetAllItems",
    "ActionSavePlanItem",
    "ActionSaveHowMuchItems",
  ]);

function getStatusTitle(status = "") {
  const lowerStatus = status.toLowerCase();
  return mappedStatus[lowerStatus]?.title ?? mappedStatus["waiting"].title;
}

function getColorStatusCell(status = "") {
  const lowerStatus = status.toLowerCase();
  return mappedStatus[lowerStatus]?.color ?? mappedStatus["waiting"].color;
}

function getIconToExpand(status) {
  return status ? "chevron-up" : "chevron-down";
}

function hasChildren(index) {
  const item = formatedListItems.value[index];
  return isArray(item.children) && !isEmpty(item.children);
}

function clearForm() {
  state.form = {
    why: "",
    where: "",
    who: "",
    when: "",
    how: "",
    how_much: "",
    what: "",
    action_plans_how_much: [],
  };
}

function openModal() {
  state.openedModal = true;
  emits("new-action");
}

function openItemOnModal(item) {
  state.form = Object.assign({}, item);
  state.openedModal = true;
}

function newChildItem(item) {
  state.form.parent_id = item.id;
  openModal();
}

async function submitActionItem() {
  if (!actionPlanId.value) {
    return;
  }
  try {
    state.form.action_plan_id = actionPlanId.value;
    await ActionSavePlanItem(state.form);
    $notify.addNotification({
      message: "Ação salva com sucesso",
      type: "success",
    });
  } catch (error) {
    $notify.addNotification({
      message: "Erro ao salvar a ação",
      type: "danger",
    });
  } finally {
    debounce(async () => await loadData(), 1000)();
    clearForm();
  }
}

async function loadData() {
  if (!actionPlanId.value) {
    return;
  }
  try {
    const result = await ActionGetAllItems({ id: actionPlanId.value });
    state.items = result.data;
  } catch (error) {}
}

onMounted(() => {
  loadData();
});
</script>
<style scoped lang="scss">
:deep(.font-weight-normal) th {
  border-top: 0 !important;
  font-weight: normal !important;
}

:deep(.custom-control-) {
  cursor: pointer !important;
}
</style>
