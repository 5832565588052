<template>
  <b-table
    :fields="fields"
    :items="items"
    show-empty
    class="border-top-0"
    thead-tr-class="font-weight-normal justify-content-center"
    tbody-tr-class="text-secondary"
  >
    <template #cell(status)="row">
      <b-badge
        :variant="getColorStatusCell(row.item.status)"
        class="font-weight-normal"
        pill
      >
        {{ getStatusTitle(row.item.status) }}
      </b-badge>
    </template>
    <template #cell(actions)="row">
      <b-button
        size="sm"
        @click="openItemOnModal(row.item)"
        pill
        variant="outline-primary"
        class="mr-1"
      >
        <b-icon icon="pencil-square" />
      </b-button>
    </template>

    <template #empty>
      <b-card class="border-0 text-center py-5">
        <b-card-text>
          <b-icon icon="exclamation-circle" class="h3" variant="warning" />
          <p class="text-muted">Ainda não foram cadastradas ações</p>
        </b-card-text>
      </b-card>
    </template>
  </b-table>
</template>
<script setup>

import format_date from "@/helpers/filters/format_date";
import { useMapGetters } from "@/composables/useMapGetters";

const emits = defineEmits(["edit-action"]);

const props = defineProps({
  items: Array,
});

const fields = [
  {
    key: "what",
    label: "Título",
    class: "col-6",
    formatter: (value) => value || "Não definido",
  },
  {
    key: "status",
    label: "Status",
    class: "text-center",
    formatter: (value) => `---- ${value}`,
  },
  {
    key: "who",
    label: "Responsável",
    class: "text-center col-2",
  },
  {
    key: "when",
    label: "Quando?",
    class: "text-center",
    formatter: (value) => format_date(value),
  },
  {
    key: "actions",
    label: "#",
    class: "col-auto text-right",
  },
];

const { GetMappedStatus } = useMapGetters("action_plans", ["GetMappedStatus"]);
const mappedStatus = GetMappedStatus();

function getStatusTitle(status = "") {
  const lowerStatus = status.toLowerCase();
  return mappedStatus[lowerStatus]?.title ?? mappedStatus["waiting"].title;
}

function getColorStatusCell(status = "") {
  const lowerStatus = status.toLowerCase();
  return mappedStatus[lowerStatus]?.color ?? mappedStatus["waiting"].color;
}

function openItemOnModal(item) {
  emits("edit-action", item);
}

</script>
<style scoped lang="scss">
:deep(.font-weight-normal) th {
  border-top: 0 !important;
  font-weight: normal !important;
}

:deep(.custom-control-) {
  cursor: pointer !important;
}
</style>
